<template>
  <div class="flex-1 mt-12 mx-4">
    <table
      class="w-full text-sm text-left rtl:text-right text-slate-500 dark:text-slate-400"
    >
      <thead
        class="text-xs text-slate-700 uppercase bg-slate-50 dark:bg-slate-700 dark:text-slate-400"
      >
        <tr>
          <th scope="col" class="bold px-6 py-3">
            {{ $t('CAMPAIGN_REPORTS.CAMPAIGN') }}
          </th>
          <th scope="col" class="bold px-6 py-3">
            {{ $t('CAMPAIGN_REPORTS.CAMPAIGN_SEND_DATE') }}
          </th>
          <th scope="col" class="bold px-6 py-3">
            {{ $t('CAMPAIGN_REPORTS.START_REPORT_DATE') }}
          </th>
          <th scope="col" class="bold px-6 py-3">
            {{ $t('CAMPAIGN_REPORTS.STATUS.HEADER') }}
          </th>
          <th scope="col" class="bold px-6 py-3">
            {{ $t('CAMPAIGN_REPORTS.CAMPAIGN_CHANNEL') }}
          </th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="campaignReport in campaignsReports"
          :key="campaignReport.id"
          class="bg-white border-b dark:bg-slate-800 dark:border-slate-700"
        >
          <td
            scope="row"
            class="px-6 py-4 font-medium text-slate-900 whitespace-nowrap dark:text-white"
          >
            {{ campaignReport.campaign.title }}
          </td>
          <td class="px-6 py-4">
            {{ formatDate(campaignReport.campaign.scheduled_at) }}
          </td>
          <td class="px-6 py-4">{{ formatDate(campaignReport.created_at) }}</td>
          <td class="px-6 py-4">
            {{
              campaignReport.status
                ? $t('CAMPAIGN_REPORTS.STATUS.READY')
                : $t('CAMPAIGN_REPORTS.STATUS.GENERATING')
            }}
          </td>
          <td class="px-6 py-4">{{ campaignReport.campaign.campaign_type }}</td>
          <td class="px-6 py-4">
            <omfy-button
              v-if="campaignReport.status"
              @click="downloadReport(campaignReport.id)"
              variant="bg-blue-700 hover:bg-blue-800"
              icon="document"
              size="small"
            >
              Download
            </omfy-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      isLoading: 'campaignsReports/getIsLoading',
      labelList: 'labels/getLabels',
      campaignsReports: 'campaignsReports/getAllCampaignsReports',
    }),
    showEmptyResult() {
      const hasEmptyResults =
        !this.isLoading && this.campaignsReports.length === 0;
      return hasEmptyResults;
    },
  },
  methods: {
    async downloadReport(id) {
      this.$store.dispatch('campaignsReports/downloadReport', id);
    },
    viewData() {
      // Implement view data functionality here
    },
    // função que trasforma essa data e hora 2025-01-11T23:05:00.000Z	em 11/01/2025 - 23:05
    formatDate(rawDate) {
      const date = new Date(rawDate);

      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const year = date.getUTCFullYear();

      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');

      return `${day}/${month}/${year} - ${hours}:${minutes}h`;
    },
  },
  created() {
    this.$store.dispatch('campaignsReports/getCampaignsReportsRequest');
  },
};
</script>
